<template>
  <div class="min-h-screen lg:h-screen flex flex-col lg:flex-row items-center justify-center">
    <div class="w-7/12 h-screen shadow-2xl hidden lg:flex aic jcc">
      <img
        src="/img/login-4.webp"
        alt="login"
        class="w-full h-screen object-cover mirror"
      >
    </div>
    <div class="flex flex-col items-center justify-start w-full lg:w-5/12 h-screen overflow-y-scroll p-7 lg:p-12 auth-back">
      <div class="flex aic jcs mb-16 w-full">
        <span class="w-5 h-12 bg-brown-lightest shadow-xl"></span>
        <router-link
          to="/"
          class="flex flex-col ais jcc ml-5"
        >
          <small class="fs-10 text-gray-600 tracking-widest uppercase">Brötchen Shop</small>
          <h1 class="roboto-bold text-xl md:text-2xl">{{$t("Login")}}</h1>
        </router-link>
        <router-link
          to="/"
          class="ml-auto"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-3 w-3 md:w-5 md:h-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z" />
          </svg>
        </router-link>
      </div>
      <form
        @submit.prevent="login"
        class="my-auto w-full max-w-md"
      >
        <div class="flex flex-col auth-area">
          <div class="rounded-4xl text-left transform transition-all">
            <div>
              <!-- <div class="flex aic jcb mb-16 bg-gray-100 p-4 text-gray-800 auth-border auth-header shadow-lg">
                <span class="auth-text-top uppercase b">{{$t("Login")}}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"
                  />
                </svg>
              </div> -->
              <div class="flex flex-col form space-y-4">
                <div>
                  <div>
                    <input
                      type="email"
                      name="email"
                      id="email-address"
                      placeholder="Email"
                      pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                      required
                      v-model="user.email"
                      class="block w-full px-3 py-6 bg-transparent border-b border-gray-300 new-input dmSans-bold"
                      aria-label="Email"
                      autocomplete="email"
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      required
                      v-model="user.password"
                      class="block w-full px-3 py-6 bg-transparent border-b border-gray-300 new-input dmSans-bold"
                      aria-label="Password"
                      placeholder="Password"
                      autocomplete="current-password"
                    />
                  </div>
                </div>
                <div class="flex aic jce mt-2">
                  <router-link
                    to="/resetpassword"
                    class="a-main smooth text-xs cursor-pointer font-medium a-black"
                  >{{$t("Forgot Password")}}</router-link>
                </div>
              </div>
            </div>
            <div class="flex flex-col md:flex-row lg:flex-col xl:flex-row space-y-8 md:space-y-0 lg:space-y-8 xl:space-y-0 aic jce mb-16 lg:mb-32 mt-12 lg:mt-24">
              <!-- <div class="flex flex-col ais jcb w-full">
                <span class="text-sm">{{$t("Don't have an account?")}}</span>
                <router-link
                  to="/register"
                  class="a-black text-sm cursor-pointer b text-left"
                >{{$t("Register here!")}}</router-link>
              </div> -->
              <button
                type="submit"
                class="inline-flex nunitoSans-bold justify-center w-full md:max-w-max lg:max-w-2xl xl:max-w-max border border-transparent relative px-16 py-3 btn-brown-light-rev"
              >
                {{$t("Login")}}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {},
    };
  },
  computed: {
    restaurantId() {
      return this.$store.state.subscription.restaurantId;
    },
  },
  methods: {
    login() {
      this.$store
        .dispatch("subscription/getUserOriginId", {
          email: this.user.email,
        })
        .then((res) => {
          if (res.type == "success") {
            this.user.restaurantId = this.restaurantId;
            localStorage.setItem("restaurantId", this.restaurantId);
            this.$store.dispatch("auth/login", this.user).then((res) => {
              if (res.type == "success") {
                this.$router.push("/dashboard");
                // this.$store.dispatch("auth/getUserAddress");
              } else {
                this.$store.commit("setshowNotif", {
                  show: true,
                  msg: res.msg,
                  type: res.type,
                });
              }
            });
          } else {
            this.$store.commit("setshowNotif", {
              show: true,
              msg: res.msg,
              type: res.type,
            });
          }
        });
    },
  },
};
</script>

<style>
</style>